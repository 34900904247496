/* global $ */
$((function(){
	setupSlideMenu();
	menu();
	menuClick();
	$("form").addClass("infield-active");
    
	sideBarTitles();
	$(".t-project .the-solution .mailchimp > p")
		.html("<p>Join our mailing list to hear about more projects like this</p>");

	setupHotspots();
	showRelatedProjects();
	headerLogo();
}));

export function translateTeaser(){
	var teaser = document.querySelector(".project.teaser");
	if (teaser){
		document.addEventListener("mousemove", function(e){
			var x = e.pageX / window.innerWidth;
			x = x * -10;
			var y = e.pageY / window.innerHeight;
			y= y * -10;
			teaser.style.webkitTransform = "translate(" + x + "%," + y + "%)";
			teaser.style.transform = "translate(" + x + "%," + y + "%)";
		});
	}
}
export function headerLogo() {

	var $window = $(window);
	var $logo = $("header.top .full-logo");

	function fadeLogo(event){
        
		var logo_height = $logo.outerHeight();
		var offset = $("#contact-content").offset();
		var contact_distance = (offset ? offset.top : null);

		var logo = event.data;

		if ( $window.scrollTop() >= logo_height * 2) {
			logo.addClass("fade");
			$(".site-content-menu-slide").removeClass("initial");
		} 
		else {
			logo.removeClass("fade");
			$(".site-content-menu-slide").addClass("initial");
		}
        
		var $contact_logo = $("div.contact-logo");
		if ( $window.scrollTop() >= contact_distance ) {
			$contact_logo.addClass("show");
		} else {
			$contact_logo.removeClass("show");
		}
	}
    
	$window.scroll($logo, fadeLogo);
	$window.resize($logo, fadeLogo);
	$window.trigger("scroll");
 
}


export function setupSlideMenu(){
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

export function menu(){
	$(".menu-button").click(function(){
		$(".menu-button").toggleClass("active");
		$(".header-bar-bottom").toggleClass("active");
		$("header.top").toggleClass("active");
	});
}

export function menuClick(){
	$(".main-menu li.has-sub > a").on("click", function(e){
		e.preventDefault();
		var openmenus = $(".open-menu");
		var $parent = $(this).parent(".main-menu li.has-sub");
		openmenus.not($parent).removeClass("open-menu");
		$parent.toggleClass("open-menu");
	});
}

export function listView() {
	$(".blog-container").addClass("list");
}

export function squareView() {
	$(".blog-container").removeClass("list");
}

export function sideBarTitles() {
	var absoluteWrapper = $(".page-titles");
	$(".move-to-sidebar").each(function(index,el){
		var $el = $(el);
		if ($el.parent().hasClass("page-titles")) return;
		var newEl = $el.clone();
		newEl.appendTo(absoluteWrapper);
		newEl.css("top", $el.offset().top+"px");
		$el.hide();
		newEl.data("original",$el);
	});
	var movedTitles = absoluteWrapper.children();
	function adjustTitles() {
		movedTitles.each(function(index,el){
			var $el = $(el);
			var $original = $el.data("original");
			$original.show();
			$el.css("top", $original.offset().top+"px");
			$original.hide();
		});
	}
	$(window).on("resize load",adjustTitles);
}

export function setupHotspots() {
	var hotspot = $(".show-hotspots");
	if (hotspot){
		hotspot.click(function() {
			// clear
			$(".show-hotspots").removeClass("active");
			$(".aus-map a.project.hotspot").removeClass("active");
			// set
			$(this).addClass("active");
			var newClass = $(this).data("class");
			$(".aus-map a.project.hotspot."+ newClass).addClass("active");
		});
	}
}

export function showRelatedProjects() {
	var show = $(".show-projects");
	show.click(function() {
		$(this).toggleClass("active");
		$(this).next(".related-projects.height").toggleClass("expanded");
	});
}

export function checksubmit(url){
    
	var sub = $("#subscribe").prop("checked");
    
	if (sub == true){
		var form = $("<form></form>");
		var csrf = $("#contact-form input[name='_csrf_token']").clone();
		form.append(csrf);
       
		form.attr("method", "get");
		form.attr("action", url);
		var fname = $("#contact-form input[name=FNAME]").clone();
		form.append(fname);
		var lname = $("#contact-form input[name=LNAME]").clone();
		form.append(lname);
		var email = $("#contact-form input[name=email]").clone();
		form.append(email);
        
		// The form needs to be a part of the document in
		// order for us to be able to submit it.
		$(document.body).append(form);
		console.log(form);
		form.submit();
	}
	return true;
}

// Make functions available to HTML references
window.translateTeaser = translateTeaser;
window.listView = listView;
window.squareView = squareView;
window.checksubmit = checksubmit;